import axios from 'axios';

// const servicesUrl = 'https://octank-ecs-lb-1051191215.us-east-1.elb.amazonaws.com';
const servicesUrl = 'https://api.octank.suresh.sa.aws.dev';

export const getPolicyList = (userid) => {
    const params = {
        params: {
            userid: userid
        }
    }
    const getlisturl = servicesUrl+'/getlist';

    return axios.get(getlisturl, params)
        .then(resp => resp.data)
        .catch(err => console.error(err));
};

export const addPolicyInfo = (userid, name, description, account) => {
    const payload = {
        "userid": userid,
        "name": name,
        "description": description,
        "account": account
    };
    const saveurl = servicesUrl+'/save';

    return axios.post(saveurl, payload)
        .then(data => { console.log(data); return data; })
        .catch(err => console.error(err));
};