import React, { Component } from 'react';
import { Container, Form, Message } from 'semantic-ui-react';

import './AddPage.css';
import { addPolicyInfo } from '../services/restService';

function App(props) {

  return (
    <div className='addpage'>
      <Container text >
        <h1>Add Insurance Policy</h1>
        <AddForm user={props.user} />
      </Container>
    </div>
  );
}

class AddForm extends Component {

  constructor(props) {
    super(props);
    this.state = { name: '', description: '', account: '', msg: '' }
  }

  componentDidMount() {

  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value })

  handleSubmit = () => {
    const { name, description, account } = this.state

    addPolicyInfo(this.props.user.id, name, description, account)
      .then((data) => this.setState({ msg: 'S' }))
      .catch(err => this.setState({ msg: 'E' }));
  }

  render() {
    const { name, description, account, msg } = this.state

    return (
      <div>
        {msg === 'S' ? <SuccessMsg /> : ''}
        {msg === 'E' ? <ErrorMsg /> : ''}
        <Form onSubmit={this.handleSubmit}>
          <Form.Group widths={2}>
            <Form.Input
              label='Policy Name'
              placeholder='Policy name'
              name='name'
              value={name}
              onChange={this.handleChange}
            />
            <Form.Input
              label='Account #'
              placeholder='Account number'
              name='account'
              value={account}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group widths={1}>
            <Form.TextArea
              label='Description'
              name='description'
              value={description}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Button content='Submit' />
        </Form>
      </div>
    )
  }
}

const SuccessMsg = () => (
  <Message
    success
    header='Successful added !'
    content=' '
  />
)

const ErrorMsg = () => (
  <Message
    error
    header='Add policy failed'
    content='Sorry please try again later !'
  />
)

export default App;