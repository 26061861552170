import React from 'react';
import { Container, Menu, Dropdown } from 'semantic-ui-react';
import { Image } from 'semantic-ui-react'

import amplifyAuthDetails from '../amplify-auth/amplifyAuthHelper';
import './Home.css';

export const UserContext = React.createContext();

function App() {
  const { state: { user } } = amplifyAuthDetails();

  return (
    <div className='mybrokerapp'>
      <Container text style={{ marginTop: '5em' }}>
        <UserContext.Provider user={user}>
          Ocank Financials
        </UserContext.Provider>
      </Container>
    </div>
  );
}

export default App;