import React, { Component, useState, useCallback } from 'react';
import './App.css';

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Menu, Dropdown } from 'semantic-ui-react';

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator, AmplifyTheme } from 'aws-amplify-react';
import '@aws-amplify/ui/dist/style.css';

import amplifyAuthDetails from './amplify-auth/amplifyAuthHelper';
import Home from './pages/Home';
import AddPage from './pages/AddPage';
import ListPage from './pages/ListPage';
import 'semantic-ui-css/semantic.min.css'

import img from './images/city1.jpg'

Amplify.configure(awsconfig);

function App() {
  const { state: { user }, onSignOut } = amplifyAuthDetails();
  const [activeItem, setActiveItem] = useState('home');

  const handleItemClick = useCallback((e, {name}) => {
    console.log(name);
    setActiveItem(name);
  });

  function UserIDBar(props) {
    return !user ? (
      <div></div>
    ) : (
        <div className="signout">Welcome {user.username} (<Link to="/" onClick={onSignOut}>Sign Out</Link>)</div>
      );
  }

  return !user ? (
    <div>loading ... </div>
  ) : (
    <Router>

    <div className="topheading">
      <div className="headingtext">
        Systems Health Dashboard
      </div>
      <UserIDBar></UserIDBar>
    </div>
    
    <div className="wrapper">
    <Menu pointing vertical className="leftmenu">
        <Menu.Item
          name='home'
          as={Link} to='/home'
          active={activeItem === 'home'}
          onClick={handleItemClick}
        />
        <Menu.Item
          name='addpage'
          as={Link} to='/addpage'
          active={activeItem === 'addpage'}
          onClick={handleItemClick}
        />
        <Menu.Item
          name='listpage'
          as={Link} to='/listpage'
          active={activeItem === 'listpage'}
          onClick={handleItemClick}
        />
      </Menu>

      <Switch>
        <Route exact path="/home">
          <Home user={user}/>
        </Route>
        <Route exact path="/addpage">
          <AddPage user={user}/>
        </Route>
        <Route exact path="/listpage">
          <ListPage user={user}/>
        </Route>
      </Switch>
      </div>
    </Router>
    // </div>
  );
}

const signUpConfig = {
  header: 'Octank Financials App Sign Up',
  hideAllDefaults: true,
  hiddenDefaults: ['phone_number']
};



const myContainer = Object.assign({}, AmplifyTheme.container, { marginTop: 0 , backgroundImage:`url(${img})`, display: 'flex', backgroundSize: '100% 100%', height:'1000px'});

// const myContainer = Object.assign({}, AmplifyTheme.container, { paddingTop: 0, backgroundColor: '#4CAFCD' });

const myTheme = Object.assign({}, AmplifyTheme, { container: myContainer});


export default withAuthenticator(App, {'theme':myTheme, signUpConfig });;
