import React, { Component } from 'react';
import { Container, Menu, Dropdown } from 'semantic-ui-react';
import { Icon, Label, Table } from 'semantic-ui-react'

import './ListPage.css';

import { getPolicyList } from '../services/restService';

function App(props) {
  return (
    <div className='listpage'>
      <Container text style={{ marginTop: '1em' }}>
        <h1>Insurance Policies</h1>
        <PolicyGrid user={props.user} />
      </Container>
    </div>
  );
}

class PolicyGrid extends Component {

  constructor(props) {
    super(props);
    this.state = { policies: [] };
  }

  componentDidMount() {
    getPolicyList(this.props.user.id)
      .then((policies) => {
        console.log(policies);
        this.setState({ policies: policies })
      })
  }

  render() {
    const { policies } = this.state

    console.log(policies);

    const rows = policies ? policies.map((p, indx) => {
      return (
        <Table.Row key={indx}>
          <Table.Cell>{p.name}</Table.Cell>
          <Table.Cell>{p.account}</Table.Cell>
          <Table.Cell>{p.description}</Table.Cell>
        </Table.Row>
      );
    }) : '';

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Policy Name</Table.HeaderCell>
            <Table.HeaderCell>Account #</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {rows}
        </Table.Body>

      </Table>
    )
  }
}

export default App;