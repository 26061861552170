/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:aa3b56af-7620-4142-a958-a86288e68510",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_jxaKSPej6",
    "aws_user_pools_web_client_id": "1jt1s0rpc5hfp9bc1pd38qhr4a",
    "oauth": {}
};


export default awsmobile;
